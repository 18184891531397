import { useMessageContext } from 'context/messageContext'
import { useCallback } from 'react'

export function useMessage() {
  const { message: messageProps, setMessage } = useMessageContext()

  const onInfoMessage = useCallback(
    (text, time = 10000) => {
      setMessage({
        open: true,
        type: 'info',
        text,
        time,
      })
    },
    [setMessage],
  )

  const onSuccessMessage = useCallback(
    (text, time = 10000) => {
      setMessage({
        open: true,
        type: 'success',
        text,
        time,
      })
    },
    [setMessage],
  )

  const onErrorMessage = useCallback(
    (text, time = 10000) => {
      setMessage({
        open: true,
        type: 'error',
        text,
        time,
      })
    },
    [setMessage],
  )

  function openMessage(type, text, time = 10000) {
    setMessage({
      open: true,
      type,
      text,
      time,
    })
  }

  function closeMessage() {
    setMessage({
      ...messageProps,
      open: false,
    })
  }

  return {
    onSuccessMessage,
    onInfoMessage,
    onErrorMessage,
    messageProps,
    openMessage,
    closeMessage,
  }
}
